import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useHistory, useParams } from "react-router";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import TitleH1 from "components/common/TitleH1";
import TitleH2 from "components/common/TitleH2";
import 'styles/pages/integrations.scss';
import BlockPublication from "components/common/integrations/BlockPublication";
import BlockReview from "components/common/integrations/BlockReview";
import BlockParser from "components/common/integrations/BlockParser";

/**
 * @param {UiStore} uiStore
 * @param {BreadcrumbsStore} breadcrumbsStore
 * @param {MainStore} mainStore
 * @param {AdPlatformStore} adPlatformStore
 * @param {adPlatformParserStore} adPlatformParserStore
 * @return {JSX.Element}
 * @constructor
 */
const IntegrationsDetailPage = ({
                                    uiStore,
                                    breadcrumbsStore,
                                    mainStore,
                                    adPlatformStore,
                                    adPlatformTypeStore,
                                    clientStore,
                                }) => {
    const history = useHistory();
    const { clientId, id } = useParams();
    const { client } = mainStore;
    const {
        entity,
        branches,
    } = adPlatformStore;

    const [adPlatformType, setAdPlatformType] = useState([]);
    const [activePlatformType, setActiveAdPlatformType] = useState(null);

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'clients',
            className: 'integrations'
        });

        if (entity.id) {
            adPlatformTypeStore.getAll().then(result => {
                setActiveAdPlatformType(result.items.find(item => item.code === entity.type.code))
                setAdPlatformType(result.items)
            })
        }

    }, [entity]);

    useEffect(() => {


        if (client.id && entity.id) {
            breadcrumbsStore.addItem({
                linkName: 'Клиенты',
                link: '/clients',
            }, 0);

            breadcrumbsStore.addItem({
                linkName: client.title,
                link: `/clients/${clientId}/detail`,
            }, 1);

            breadcrumbsStore.addItem({
                linkName: 'Интеграции',
                link: `/clients/${clientId}/integrations`,
            }, 2);

            breadcrumbsStore.addItem({
                linkName: `${entity.title}`,
                link: `/clients/${clientId}/integrations/${id}/detail`,
            }, 3);
        }
    }, [client, entity])


    useEffect(() => {
        clientStore.loadEntity(clientId)
            .then(() => {
                mainStore.setClient(clientStore.entity)
            });
        adPlatformStore.loadEntity(id, false);

        adPlatformTypeStore.getAll().then(result => {
            setAdPlatformType(result.items)
        });

    }, []);

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    ico={'chain_gradient'}
                    showBadge={true}
                    active={entity.enabled}
                >
                    {entity.title} / {client.title}
                </TitleH1>
            </div>

            <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <Button
                            className={cn('p-button-outlined')}
                            label="Редактировать"
                            icon="pi pi-pencil"
                            onClick={() => {
                                history.push(`/clients/${clientId}/integrations/${id}/edit`);
                            }}
                        />
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                <Dropdown
                                    value={entity.type.code}
                                    options={adPlatformType}
                                    id="platform"
                                    name="platform"
                                    optionLabel="title"
                                    optionValue="code"
                                    placeholder="Площадка"
                                    disabled
                                />
                                    <label htmlFor="platform">Площадка</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='id'
                                        name='id'
                                        type='text'
                                        value={entity.id}
                                        disabled
                                    />
                                    <label htmlFor="id">Okreview ID</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col', 'edit-form__col_large')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='url'
                                        name='url'
                                        type='text'
                                        value={entity.accountUrl}
                                        disabled
                                    />
                                    <label htmlFor="url">Страница на площадке</label>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        {entity.id && activePlatformType?.features.includes('publication') && <BlockPublication
                            integrationId={entity.id}
                            disabled={true}
                        />}
                    </div>
                    <div className={cn('edit-form__row')}>
                        {entity.id && activePlatformType?.features.includes('extreview') && <BlockReview
                            integrationId={entity.id}
                            disabled={true}
                        />}
                    </div>
                    <>
                        {/*Идентификатор парсинга площадки*/}
                        {entity.id && activePlatformType?.features.includes('collector') && <BlockParser
                            integrationId={entity.id}
                            type={entity.type.code}
                            clientId={clientId}
                            disabled={true}
                            activePlatformType={activePlatformType}
                        />}
                    </>
                </div>
            </div>

            <div className={cn('detail-container')}>
                <div className={cn('detail')}>
                    <div className={cn('table-container', 'branches')}>
                        <div className={cn('title-container')}>
                            <TitleH2
                                count={branches.length}
                                color='color-lilac'
                                ico='house'
                            >
                                Филиалы / {client.title}
                            </TitleH2>
                        </div>

                        <table>
                            <thead>
                            <tr>
                                <th>Филиал</th>
                                <th>Связь с интеграцией</th>
                            </tr>
                            </thead>
                            <tbody>
                            {branches.map((item, key) => (
                                <tr key={key}>
                                    <td>
                                        <Link to={`/clients/${clientId}/branches/${item.id}/detail`}>{item.title}</Link>
                                    </td>
                                    <td>
                                        <span className={cn('badge', `badge_linked-true`)}>Связаны</span>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

IntegrationsDetailPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    mainStore: PropTypes.any,
    adPlatformStore: PropTypes.any,
    adPlatformTypeStore: PropTypes.any,
    clientStore: PropTypes.any,
}

export default inject(
    'uiStore',
    'breadcrumbsStore',
    'mainStore',
    'adPlatformStore',
    'adPlatformTypeStore',
    'clientStore',
)(observer(IntegrationsDetailPage));



