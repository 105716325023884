import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useHistory, useParams } from "react-router";
import { inject, observer } from "mobx-react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";

import TitleH1 from "components/common/TitleH1";
import TitleH2 from "components/common/TitleH2";
import CustomizableLinkedSwitch from "../../../common/CustomizableLinkedSwitch";
import OkInputText from "components/common/OkInputText";
import OkDropdown from "components/common/OkDropdown";
import BlockPublication from "components/common/integrations/BlockPublication";
import BlockReview from "components/common/integrations/BlockReview";
import BlockParser from "components/common/integrations/BlockParser";

/**
 * @param {UiStore} uiStore
 * @param {BreadcrumbsStore} breadcrumbsStore
 * @param {MainStore} mainStore
 * @param {AdPlatformStore} adPlatformStore
 * @param {adPlatformParserStore} adPlatformParserStore
 * @return {JSX.Element}
 * @constructor
 */
const IntegrationsEdit = ({
                              uiStore,
                              breadcrumbsStore,
                              mainStore,
                              adPlatformStore,
                              adPlatformTypeStore
                          }) => {
    const history = useHistory();
    const { clientId, id } = useParams();
    const {
        entity,
        setEntity,
        editableBranches,
        setBranchLinked,
        delBranchLinked,
    } = adPlatformStore;
    const { client } = mainStore;
    const [isValid, setIsValid] = useState(false);
    const [isDisabledField, setIsDisabledField] = useState(false);
    const [globalActive, setGlobalActive] = useState(true);
    const [adPlatformType, setAdPlatformType] = useState([]);
    const [activePlatformType, setActiveAdPlatformType] = useState(null);

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'clients',
            className: 'integrations'
        });

        setIsValid((
            !!entity.type.code.length &&
            !!entity.title.length
        ));

        if(entity.id) {
            adPlatformTypeStore.getAll().then(result => {
                setActiveAdPlatformType(result.items.find(item => item.code === entity.type.code))
                setAdPlatformType(result.items)
            })
        }
    }, [entity]);

    useEffect(() => {
        if (client.id && entity.id) {
            breadcrumbsStore.addItem({
                linkName: 'Клиенты',
                link: '/clients',
            }, 0);

            breadcrumbsStore.addItem({
                linkName: client.title,
                link: `/clients/${clientId}/detail`,
            }, 1);

            breadcrumbsStore.addItem({
                linkName: 'Интеграции',
                link: `/clients/${clientId}/integrations`,
            }, 2);

            breadcrumbsStore.addItem({
                linkName: 'Редактирование интеграции',
                link: `/clients/${clientId}/integrations/${id}/detail`,
            }, 3);
        }
    }, [client, entity])

    useEffect(() => {
        setGlobalActive(entity.enabled);
    }, [entity.enabled])

    useEffect(() => {
        adPlatformStore.loadEntity(id, true);
    }, []);

    const [saveChild, setSaveChild] = useState(false);

    const save = () => {
        adPlatformStore
            .saveEntity()
            .then(() => {

                setSaveChild(true);
                setTimeout(() => {
                    history.push(`/clients/${clientId}/integrations/${id}/detail`);
                }, 300)

            })
            .catch(() => {
            });
    };

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    ico={'chain_gradient'}
                >
                    {entity.title} / Редактирование
                </TitleH1>
            </div>

            <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                label="Сохранить изменения"
                                icon="pi pi-save"
                                onClick={() => {
                                    save();
                                }}
                                disabled={!isValid}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Отмена"
                                onClick={() => {
                                    history.push(`/clients/${clientId}/integrations/${id}/detail`)
                                }}
                            />
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col', 'edit-form__col_large')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='name'
                                        name='name'
                                        type='text'
                                        value={entity.title}
                                        isrequired="true"
                                        onChange={(e) => {
                                            setEntity({ title: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="name">Название интеграции *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                            <div className={cn('switch', 'switch-activity')}>
                                <InputSwitch
                                    checked={entity.enabled}
                                    onChange={(e) => {
                                        setEntity({ enabled: e.value });
                                    }}
                                />
                                <span className={cn('switch__text')}>{entity.enabled ? 'Активна' : 'Не активна'}</span>
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                    <span className="p-float-label">
                                    <OkDropdown
                                        value={entity.type.code}
                                        options={adPlatformType}
                                        isrequired="true"
                                        onChange={(e) => {
                                            const result = adPlatformType.find(item => item.code === e.value)
                                            setActiveAdPlatformType(result);

                                            setEntity({
                                                type: {
                                                    ...entity.type,
                                                    ...{
                                                        code: e.value
                                                    },
                                                },
                                            });
                                        }}
                                        id="platform"
                                        name="platform"
                                        optionLabel="title"
                                        optionValue="code"
                                        disabled={isDisabledField}
                                    />
                                        <label htmlFor="name">Площадка *</label>
                                    </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='id'
                                        name='id'
                                        type='text'
                                        value={entity.id}
                                        disabled={true}
                                    />
                                    <label htmlFor="id">Okreview ID</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col', 'edit-form__col_large')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='url'
                                        name='url'
                                        type='text'
                                        value={entity.accountUrl}
                                        onChange={(e) => {
                                            setEntity({ accountUrl: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="url">Страница на площадке</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    {/*Функционал автоматической публикации отзывов на Авито*/}
                    {/*публикация*/}
                    {entity.id && activePlatformType?.features.includes('publication') && <div className="edit-form__row">
                        <BlockPublication
                            onSave={saveChild}
                            integrationId={entity.id}
                            active={globalActive}
                        />
                    </div>}

                    <div className="edit-form__row">
                        {/*Функционал проактивного сбора отзывов*/}
                        {/*сбор отзывов*/}
                        {entity.id && activePlatformType?.features.includes('collector') && <BlockReview
                            onSave={saveChild}
                            integrationId={entity.id}
                            active={globalActive}
                        />}
                    </div>
                    <>
                        {/*Идентификатор парсинга площадки*/}
                        {/*парсер*/}
                        {entity.id && activePlatformType?.features.includes('extreview') && <BlockParser
                            onSave={saveChild}
                            integrationId={entity.id}
                            type={entity.type.code}
                            clientId={clientId}
                            handleDisabled={setIsDisabledField}
                            active={globalActive}
                            activePlatformType={activePlatformType}
                        />}
                    </>
                </div>
            </div>

            <div className={cn('detail-container')}>
                <div className={cn('detail')}>
                    <div className={cn('table-container', 'branches')}>
                        <div className={cn('title-container')}>
                            <TitleH2
                                count={editableBranches.length}
                                color='color-lilac'
                                ico='house'
                            >
                                Филиалы / {client.title}
                            </TitleH2>
                        </div>

                        <div className={cn('buttons-container')}>
                            <Button
                                className={cn('p-button-outlined', 'color-lilac')}
                                label="Добавить филиал"
                                icon="pi pi-plus"
                                onClick={() => {
                                    history.push(`/clients/${clientId}/branches/new`);
                                }}
                            />
                        </div>

                        <table>
                            <thead>
                            <tr>
                                <th>Филиалы</th>
                                <th>Связь с интеграцией</th>
                            </tr>
                            </thead>
                            <tbody>
                            {editableBranches.map((item, key) => (<tr key={key}>
                                <td>
                                    <a href="clients/integrations#">{item.branch.title}</a>
                                </td>
                                <td>
                                    <div className={cn('switch', 'connection')}>
                                        <CustomizableLinkedSwitch
                                            initialLinked={item.linked}
                                            item={item}
                                            switchTextOn="Связаны"
                                            switchTextOff="Нет связи"
                                            changeHandler={(e) => {
                                                if (e.linked) {
                                                    setBranchLinked(e.item.branch.id);
                                                } else {
                                                    delBranchLinked(e.item.branch.id);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>))}
                            </tbody>
                        </table>

                        <div className={cn('buttons-container', 'buttons-container__position_bottom')}>
                            <div className={cn('button-container')}>
                                <Button
                                    label="Сохранить изменения"
                                    icon="pi pi-save"
                                    onClick={() => {
                                        save();
                                    }}
                                />
                            </div>
                            <div className={cn('button-container')}>
                                <Button
                                    className={cn('p-button-outlined')}
                                    label="Отмена"
                                    onClick={() => {
                                        history.push(`/clients/${clientId}/integrations/${id}/detail`)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

IntegrationsEdit.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    mainStore: PropTypes.any,
    adPlatformStore: PropTypes.any,
    adPlatformTypeStore: PropTypes.any,
}

export default inject(
    'uiStore',
    'breadcrumbsStore',
    'mainStore',
    'adPlatformStore',
    'adPlatformTypeStore',
)(observer(IntegrationsEdit));
